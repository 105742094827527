import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import SearchIcon from '../../../assets/icons/search.svg';
import { listStudentsGQL } from '../../../apollo/queries';
import { Container, Title } from './helper';

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 30px;

  & > input {
    max-width: 300px;
    width: 100%;
    border: 1px solid ${$.color.gray};
    padding: 5px 10px 5px 30px;
    border-radius: 5px;
    outline: none;
    margin-right: 10px;
  }

  & > svg {
    position: absolute;
    left: 5px;
    fill: ${$.color.gray};
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow: scroll;
`;

const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid ${$.color.gray};
  width: 100%;

  thead {
    tr {
      background-color: ${$.color.gray};
      td {
        text-align: center;
        padding: 10px;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        border-right: 1px solid ${$.color.black};
        &:last-child {
          border-right: none;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid ${$.color.gray};

      &:last-child {
        border-bottom: none;
      }

      &.selected {
        background-color: ${$.color.blue5};
        color: ${$.color.white};
      }

      td {
        padding: 10px;
        font-size: 14px;
        input {
          display: block;
          margin: auto;
          &:hover {
            cursor: pointer;
          }
        }
        &:first-child {
          text-align: center;
          font-style: italic;
        }
        &:nth-child(2) {
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
  }
`;

const ChooseStudent = ({ options, setOptions }) => {
  const [studentLoadingMessage, setStudentLoadingMessage] = useState('');
  const [studentData, setStudentData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [listStudents] = useLazyQuery(listStudentsGQL, {
    onCompleted: ({ listStudents: data }) => {
      setStudentLoadingMessage('Ready.');

      setStudentData(data);
      setTimeout(() => {
        setStudentLoadingMessage('');
      }, 2000);
    },
    onError: () => {
      setStudentLoadingMessage('Error. Please try again.');
      setTimeout(() => {
        setStudentLoadingMessage('');
      }, 2000);
    },
  });

  // First time loading the form, we pull the necessary data to get started.
  useEffect(() => {
    // setStudentData(testData);
    setStudentLoadingMessage('Loading...');
    listStudents();
  }, []);

  return (
    <Container>
      <Title>Choose Student:</Title>
      <SearchContainer>
        <SearchIcon />
        <input
          type="text"
          value={searchTerm || ''}
          placeholder="Search student name/email/id"
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
        <div>{studentLoadingMessage}</div>
      </SearchContainer>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <td>Student ID</td>
              <td>Name</td>
              <td>Email</td>
              <td>Select</td>
            </tr>
          </thead>
          <tbody>
            {(!studentData || !searchTerm) && (
              <tr>
                <td colSpan="4">No result found.</td>
              </tr>
            )}
            {searchTerm &&
              studentData
                ?.filter(({ student_id: sid, name, email }) => {
                  const st = searchTerm.toLowerCase();

                  return (
                    sid.toLowerCase().indexOf(st) > -1 ||
                    name.toLowerCase().indexOf(st) > -1 ||
                    email.toLowerCase().indexOf(st) > -1
                  );
                })
                .map(({ student_id: sid, name, email }) => (
                  <tr
                    className={options?.student_id === sid ? 'selected' : ''}
                    key={sid}
                  >
                    <td>{sid}</td>
                    <td>{name}</td>
                    <td>{email}</td>
                    <td>
                      <input
                        type="radio"
                        value={sid}
                        name="selectStudent"
                        checked={sid === options?.student_id || false}
                        onChange={(e) => {
                          setOptions((prev) => ({
                            ...prev,
                            student_id: e.target.value,
                          }));
                        }}
                      />
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

ChooseStudent.defaultProps = {
  options: { student_id: '', date_id: '', datepicker_date: '', hours: 0 },
  setOptions: () => {},
};

ChooseStudent.propTypes = {
  options: PropTypes.shape({
    student_id: PropTypes.string,
    hours: PropTypes.number,
  }),
  setOptions: PropTypes.func,
};

export default ChooseStudent;
