import { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import $ from '../../../styles/global';
import ChooseStudent from './ChooseStudent';
import ChooseTimeslot from './ChooseTimeslot';
import ExtraHoursTimetable from './ExtraHoursTimetable';
import { updateHoursGQL } from '../../../apollo/mutations';

const Section = styled.section`
  padding: 40px 0;
`;

const Container = styled.div``;

const SubmitButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
  background-color: ${$.color.blue5};
  border-radius: 5px;
  padding: 6px 15px;
  color: ${$.color.white};
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    border: 1px solid ${$.color.blue5};
    color: ${$.color.blue5};
    background-color: ${$.color.white};
  }
`;

const ExtraHours = () => {
  const [options, setOptions] = useState({
    student_id: '',
    hours: 0,
    datepicker_date: '',
    class_status: 'offline',
  });
  const [extraHours, setExtraHours] = useState([]);
  const [updateHours] = useMutation(updateHoursGQL, {
    onCompleted: ({ updateHours: res }) => {
      if (res.hours) {
        setExtraHours(res.hours);
      }
    },
  });

  return (
    <Section>
      <Container>
        <ChooseStudent options={options} setOptions={setOptions} />
        {options?.student_id && (
          <>
            <ChooseTimeslot options={options} setOptions={setOptions} />
            <ExtraHoursTimetable
              data={extraHours}
              options={options}
              id={options.student_id}
            />
          </>
        )}
        {options?.student_id &&
        ((options?.date_id && options.date_id !== 'any') ||
          (options.date_id === 'any' && options.datepicker_date)) &&
        options?.hours ? (
          <SubmitButton
            onClick={() => {
              updateHours({
                variables: {
                  params: {
                    student_id: options.student_id,
                    date_id:
                      options.date_id === 'any' && options.datepicker_date
                        ? options.datepicker_date
                        : options.date_id,
                    hours: options.hours,
                    class_status: options.class_status,
                  },
                },
              });
              setOptions((prev) => ({
                ...prev,
                date_id: '',
                hours: 0,
                datepicker_date: '',
                class_status: 'offline',
              }));
            }}
          >
            Submit
          </SubmitButton>
        ) : (
          ''
        )}
      </Container>
    </Section>
  );
};

export default ExtraHours;
