import { gql } from 'apollo-boost';

const verifyAttendancesGQL = gql`
  mutation verifyAttendances($students: [StudentAttendanceInput]!) {
    verifyAttendances(students: $students) {
      student_id
      instructor_id
      verified
      date_id
    }
  }
`;

export default verifyAttendancesGQL;
