import { gql } from 'apollo-boost';

const addAttendanceGQL = gql`
  mutation addAttendance($params: AttendanceObjectInput!) {
    addAttendance(params: $params) {
      error
      class_status
      student_id
      date_id
      time_slot_taken
    }
  }
`;

export default addAttendanceGQL;
