import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';

import $ from '../../../styles/global';
import { getHoursGQL } from '../../../apollo/queries';
import { updateHoursGQL } from '../../../apollo/mutations';
import RefreshIcon from '../../../assets/icons/refresh.svg';
import MinusCircleIcon from '../../../assets/icons/minus_circle.svg';

const Container = styled.div`
  width: 100%;
  overflow: scroll;
  margin-bottom: 40px;
`;

const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid ${$.color.gray};
  width: 100%;

  thead {
    tr {
      background-color: ${$.color.gray};
      td {
        text-align: center;
        padding: 10px;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        border-right: 1px solid ${$.color.black};
        &:last-child {
          border-right: none;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid ${$.color.gray};

      &:last-child {
        border-bottom: none;
      }

      &.selected {
        background-color: ${$.color.blue5};
        color: ${$.color.white};
      }

      td {
        padding: 10px;
        font-size: 14px;
        input {
          display: block;
          margin: auto;
          &:hover {
            cursor: pointer;
          }
        }
        & > svg {
          display: block;
          width: 24px;
          height: 24px;
          fill: ${$.color.red};
          margin: auto;
          &:hover {
            cursor: pointer;
            opacity: 0.5;
          }
        }
        &:first-child {
          text-align: center;
          font-style: italic;
        }
        &:nth-child(2) {
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
  }
`;

const Refresh = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  margin-bottom: 10px;

  & > svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const OnlinePill = styled.div`
  background-color: rgba(4, 123, 67, 0.2);
  font-size: 14px;
  color: rgb(4, 123, 67);
  padding: 5px 10px;
  border-radius: 15px;
  letter-spacing: 0.5px;
  text-align: center;
  font-weight: bold;
  max-width: 80px;
  margin: auto;
`;

const OfflinePill = styled(OnlinePill)`
  background-color: rgba(201, 29, 29, 0.2);
  color: #c91d1d;
`;

const ExtraHoursTimetable = ({ data, options }) => {
  const [extraHours, setExtraHours] = useState([]);
  const [refreshMessage, setRefreshMessage] = useState('Refresh');
  const [getHours] = useLazyQuery(getHoursGQL, {
    onCompleted: ({ getHours: res }) => {
      setExtraHours(res.hours || []);
      setRefreshMessage('Updated');

      setTimeout(() => {
        setRefreshMessage('Refresh');
      }, 1000);
    },
    onError: () => {
      setRefreshMessage('Updated');
      setTimeout(() => {
        setRefreshMessage('Refresh');
      }, 1000);
    },
  });
  const [removeHours] = useMutation(updateHoursGQL, {
    onCompleted: ({ updateHours: res }) => {
      setExtraHours(res.hours || []);
    },
  });

  useEffect(() => {
    getHours({ variables: { student_id: options.student_id } });
  }, [options.student_id]);

  // After first load
  useEffect(() => {
    setExtraHours(data);
  }, [data]);

  return (
    <Container>
      <Refresh
        onClick={() => {
          getHours({ variables: { student_id: options.student_id } });
          setRefreshMessage('Refreshing...');
        }}
      >
        <RefreshIcon />
        {refreshMessage}
      </Refresh>
      <Table>
        <thead>
          <tr>
            <td>Class/Timeslot</td>
            <td>Hours</td>
            <td>Class Status</td>
            <td>Remove</td>
          </tr>
        </thead>
        <tbody>
          {extraHours.map(({ date_id: did, hours, class_status: cs }) => {
            const getDateString = () => {
              const arr = did.split('_');

              switch (did) {
                case 'any':
                  return 'Any';
                default:
                  if (arr[3]) {
                    return `${arr[1]} ${arr[0]} ${arr[2]} (Extra Session)`;
                  }

                  return `${arr[1]} ${arr[0]} ${arr[2]}`;
              }
            };

            return (
              <tr key={did}>
                <td>{getDateString()}</td>
                <td>{hours > 0 ? `+${hours}` : hours}</td>
                <td>
                  {cs === 'online' ? (
                    <OnlinePill>Online</OnlinePill>
                  ) : (
                    <OfflinePill>Offline</OfflinePill>
                  )}
                </td>
                <td>
                  <MinusCircleIcon
                    onClick={() => {
                      removeHours({
                        variables: {
                          params: {
                            student_id: options.student_id,
                            hours: 0,
                            date_id: did,
                            class_status: cs,
                          },
                        },
                      });
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

ExtraHoursTimetable.defaultProps = {
  data: [],
  options: { student_id: '' },
};

ExtraHoursTimetable.propTypes = {
  options: PropTypes.shape({
    student_id: PropTypes.string,
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date_id: PropTypes.string,
      hours: PropTypes.number,
    })
  ),
};

export default ExtraHoursTimetable;
