import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';

import $ from '../../../styles/global';
import { Container, Title } from './helper';
import { getAttendanceReportGQL } from '../../../apollo/queries';
import AddCircleIcon from '../../../assets/icons/add_circle.svg';
import MinusCircleIcon from '../../../assets/icons/minus_circle.svg';

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    align-items: flex-start;

    & > * {
      margin-right: 0;
    }

    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  // #endregion
`;

const AttendanceDropdown = styled.select`
  border-radius: 10px;
  padding: 5px 10px;
  border: 1px solid ${$.color.gray};
  font-size: 16px;
  width: 45%;
  outline: none;
  margin-right: 20px;
  user-select: none;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const HoursContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > svg {
    width: 24px;
    height: 24px;
    transition: opacity 0.3s ease;
    &:first-of-type {
      fill: ${$.color.red};
      margin-right: 10px;
    }
    &:last-of-type {
      fill: ${$.color.green};
      margin-left: 10px;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

  & > span {
    text-align: center;
    display: block;
    user-select: none;
    min-width: 20px;
  }
`;

const HoursTitle = styled.div`
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: bold;
  user-select: none;
`;

const DatePicker = styled.input.attrs({ type: 'date' })`
  border-radius: 10px;
  padding: 5px 10px;
  border: 1px solid ${$.color.gray};
  font-size: 16px;
  width: calc(65% - 20px - 2px);
  outline: none;
  margin-right: 20px;
  user-select: none;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - 20px - 2px);
  }
  // #endregion
  margin-top: 20px;
`;

const ClassStatusDropdown = styled.select`
  border-radius: 10px;
  padding: 5px 10px;
  border: 1px solid ${$.color.gray};
  font-size: 16px;
  outline: none;
  margin-right: 20px;
  user-select: none;
`;

const ChooseTimeslot = ({ options, setOptions }) => {
  const [attendance, setAttendance] = useState();
  const [datePicker, setDatePicker] = useState(false);
  const [listAttendance] = useLazyQuery(getAttendanceReportGQL, {
    onCompleted: ({ getAttendanceReport: data }) => {
      setAttendance(data);
    },
  });

  useEffect(() => {
    listAttendance({ variables: { student_id: options.student_id } });
    setOptions((prev) => ({
      ...prev,
      date_id: '',
      hours: 0,
      datepicker_date: '',
      class_status: 'offline',
    }));
    setDatePicker(false);
  }, [options.student_id]);

  useEffect(() => {
    if (options.datepicker_date === '') {
      setDatePicker(false);
    }
  }, [options.datepicker_date]);

  return (
    <Container>
      <Title>Choose Class/Timeslot, Class Status And Hours:</Title>
      <InnerContainer>
        <AttendanceDropdown
          value={options.date_id || ''}
          onChange={(e) => {
            const chosenDate = e.target.value;
            const getClassStatus = () => {
              const filter = attendance.attendance.filter(
                ({ date_id: did }) => did === chosenDate
              );

              if (filter && filter.length > 0 && chosenDate !== 'any') {
                return filter[0].class_status;
              }

              return 'offline';
            };

            setDatePicker(chosenDate === 'any');
            setOptions((prev) => ({
              ...prev,
              datepicker_date: '',
              date_id: chosenDate,
              class_status: getClassStatus(),
            }));
          }}
        >
          <option value="">--Choose Class/Timeslot--</option>
          <option value="any">[No Class/Timeslot]: Additional Hours</option>
          {attendance?.attendance.map(
            ({ date_id: did, time_slot_taken: tst }) => {
              const parsedDate = () => {
                const arr = did.split('_');

                return `${arr[1]} ${arr[0]} ${arr[2]}${
                  arr[3] ? ' (Extra Session)' : ''
                }`;
              };

              return (
                <option
                  key={did}
                  value={did}
                >{`[${parsedDate()}]: ${tst}`}</option>
              );
            }
          )}
        </AttendanceDropdown>
        <ClassStatusDropdown
          disabled={options.date_id !== 'any'}
          onChange={(e) => {
            setOptions((prev) => ({
              ...prev,
              class_status: e.target.value,
            }));
          }}
          value={options.class_status || 'offline'}
        >
          <option value="offline">Offline</option>
          <option value="online">Online</option>
        </ClassStatusDropdown>
        <HoursContainer>
          <HoursTitle>Hours:</HoursTitle>
          <MinusCircleIcon
            onClick={() => {
              setOptions((prev) => ({
                ...prev,
                hours: prev.hours - 1,
              }));
            }}
          />
          <span>
            {options.hours > 0 ? `+${options.hours}` : `${options.hours}`}
          </span>
          <AddCircleIcon
            onClick={() => {
              setOptions((prev) => ({
                ...prev,
                hours: prev.hours + 1,
              }));
            }}
          />
        </HoursContainer>
      </InnerContainer>
      {datePicker && (
        <DatePicker
          onChange={(e) => {
            const d = new Date(e.target.value);
            const arr = d.toDateString().split(' ');

            setOptions((prev) => ({
              ...prev,
              datepicker_date: `${arr[1]}_${Number(arr[2])}_${arr[3]}`,
            }));
          }}
        />
      )}
    </Container>
  );
};

ChooseTimeslot.defaultProps = {
  options: {
    student_id: '',
    date_id: '',
    datepicker_date: '',
    hours: 0,
    class_status: 'offline',
  },
  setOptions: () => {},
};

ChooseTimeslot.propTypes = {
  options: PropTypes.shape({
    student_id: PropTypes.string,
    hours: PropTypes.number,
    date_id: PropTypes.string,
    datepicker_date: PropTypes.string,
    class_status: PropTypes.string,
  }),
  setOptions: PropTypes.func,
};

export default ChooseTimeslot;
