import styled from 'styled-components';

import $ from '../../../styles/global';

const Container = styled.div`
  border: 1px solid ${$.color.gray};
  border-radius: 5px;
  padding: 20px;
  position: relative;
  margin-bottom: 40px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    padding: 30px 20px 20px 20px;
  }
  // #endregion
`;

const Title = styled.div`
  position: absolute;
  font-size: 14px;
  top: -8px;
  left: 15px;
  background-color: ${$.color.white};
  padding: 0 4px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

export { Container, Title };
