import { gql } from 'apollo-boost';

const updateHoursGQL = gql`
  mutation updateHours($params: ExtraHoursInput!) {
    updateHours(params: $params) {
      error
      student_id
      hours {
        date_id
        hours
        class_status
      }
    }
  }
`;

export default updateHoursGQL;
